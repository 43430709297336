@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/web/fonts/Arial_Unicode.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* SF Compact Display */
@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/web/fonts/SF-Compact-Display-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/web/fonts/SF-Compact-Display-Ultralight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/web/fonts/SF-Compact-Display-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/web/fonts/SF-Compact-Display-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/web/fonts/SF-Compact-Display-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/web/fonts/SF-Compact-Display-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/web/fonts/SF-Compact-Display-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/web/fonts/SF-Compact-Display-Heavy.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/web/fonts/SF-Compact-Display-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}




:root {
    --mantine-color-scheme: 'light' !important;
    --main-color: #44C5A5;
    --base-gray: #f1f1f1;
    --off-gray: #e9e9e9;
    --item-gray: #9D9D9D;
    --text-gray: #5f6368;
    --trans: #ffffff00;
    --px11: 0.6875rem;
    --px12: 0.75rem;
    --px13: 0.813rem;
    --px14: 0.875rem;
    --px15: 0.9375rem;
    --px16: 1rem;
    --px17: 1.0625rem;
    --px18: 1.125rem;
    --px19: 1.1875rem;
    --px20: 1.25rem;
    --titleFont: 2.2rem;
    --hoverBorder: rgb(231, 231, 231);
    --hoverBorder: rgba(231, 231, 231, 0.068);
    --frostedBorder: solid 1.5px rgba(255, 255, 255, 0.3);
    --heroFont: 3.6rem;
    --frostedBackground: rgba(255, 255, 255, 0.071);
    --grayBackground: #181818;
    --gradientedFrostedBorder: solid 1.5px;
    --frostedText: rgba(255, 255, 255, 0.7);
    --hoverBorder: rgb(231, 231, 231);

    /*didnt remember if i was suppposed to make my own so i did */
    --purple: #B868FF;
    --gray-text: rgba(255, 255, 255, .81);
}


* {
    padding: 0;
    margin: 0;
    font-family: "SFPRO", sans-serif;
    color: white;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}

button {
    cursor: pointer;
}

a {
    text-decoration: none;
}

body {
    /* background-color: #131313; */
    background-color: rgb(30, 30, 30);
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

* {
    accent-color: black;
    color: white;

}

nav {
    display: flex;
    transition: 0.4s ease;

    width: 100%;
    max-width: 1340px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 4;
    height: 80px;
    border: solid 1px transparent;

}

ul {
    list-style-type: none;
}

nav>ul {
    display: flex;
    gap: 29px;
}

li>a {
    font-weight: 500;
    font-size: 16px;
    font-family: 'SF Pro Display', sans-serif;
}

nav>a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 15px;
}

nav>button {
    display: none;
}

#downloadMobile {
    display: none;
}

nav>a>p {
    font-weight: 700;
    font-size: 23px;
    font-family: 'SF Pro Display', sans-serif;

}

.scrolled-nav {
    background-color: #22242bcf;

    backdrop-filter: blur(30px);
    width: 800px;
    gap: 50px;
    margin-top: 20px;
    border-radius: 50px;
    padding: 15px 0px;
    padding-left: 10px;
    padding-right: 10px;
    height: fit-content;
    border: solid 1px #ffffff16
}

#logo {
    border-radius: 9px;
    height: 43px;
}



.mainPage {

    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.mainPageBackground {

    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    backdrop-filter: blur(70px);
    /* i changed this from 244 on the mockup to 70 here
    cuz on my screen anyway it didnt shine through much
    and it also skewed where it looked like the circles were, vastly */
    background-color: rgba(27, 29, 35, .86);

}

.circleAlign {
    z-index: 1;
    max-width: 1100px;
    width: 100%;
    position: absolute;
    height: 100%;
}

.circles {
    width: 420px;
    height: 420px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    z-index: 1;
}

.lightblue {
    background-color: var(--main-color);
}

#circ1 {
    right: -315px;
    top: -120px;
}

#circ2 {
    left: -210px;
    top: 700px;
}

#circ3 {
    left: -105px;
    top: 1350px;
}

#circ4 {
    right: -105px;
    top: 2185px;
}

#circ5 {
    left: -105px;
    bottom: 3000px;
}

#circ6 {
    right: -105px;
    bottom: 2510px;
}

#circ7 {
    left: -105px;
    bottom: 1750px;
}

#circ8 {
    right: -105px;
    bottom: 1090px;
}

#circ9 {
    left: -305px;
    bottom: 790px;
}

#circ10 {
    right: -100px;
    bottom: 135px;
}

.green {
    background-color: rgba(193, 255, 60);
    /* i took the opacity you had in the mockup off this green,
    cuz on my screen anyway it didnt really shine through with it
    at all */
}

.darkblue {
    background-color: #3BA4FF;
}

.purple {
    background-color: #A64AF7;
}

.mainContent {

    width: 100%;
    padding-bottom: 50px;
    height: fit-content
}

/* hero */


.download-btn {
    background-color: transparent;
    border: none;
}


.download-btn>img {
    object-fit: cover;
    width: 145px;
}

.hero {
    height: 1030px;
    width: 100%;
    background-color: rgba(34, 36, 43, .47);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    overflow: hidden;
}

.heroContent {
    position: relative;
    bottom: -270px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heroContent>h1 {
    width: 440px;
    text-align: center;
    font-size: 55px;
    line-height: 59px;
    font-weight: 700;
}

.heroContent>h1>span {
    background: linear-gradient(to right, #64F4D1, #429E88);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

}

.heroContent>p {
    width: 430px;
    text-align: center;
    font-size: 18px;
    line-height: 29px;
    color: var(--gray-text);
    font-weight: 500;
    margin-top: 10px;
}

.heroContent>p>span {
    font-weight: 600;
    color: var(--gray-text);
}

.heroContent>img {

    margin-right: 345px;
    margin-top: 60px;
    width: 790px;
}

/* first card section */
.section-home {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    align-items: center;
    margin: 0 40px;
}

.compareFood {

    margin-top: 135px;
}

.cardsContainer-home {
    display: flex;
    gap: 25px;
    flex-direction: column;
    max-width: 1100px;
    width: 100%;

}

.compareCards>h2 {
    width: 620px;
}

/* nav hamburger */
.h2Home {
    font-weight: 700;
    font-size: 46px;
    line-height: 52px;
}

#span1 {
    background: linear-gradient(to right, #64F4D1, #419984);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.compareCards>p {
    max-width: 810px;

}

.p-home {
    line-height: 36px;
    font-size: 26px;
    font-weight: 500;
    color: var(--gray-text);
    margin-bottom: 20px;
}

.compareCard1 {

    display: flex;
    justify-content: space-between;
    height: 435px;
}

.cardLeftP {
    padding: 0 40px;
}

.compareCard1>img {
    height: 400px;
    object-fit: contain;
}

.card1Text {
    max-width: 365px;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
}

.card1Text>h5 {
    color: #64F4D1;
}

.card1Text>p {
    margin-top: auto;
    padding-bottom: 10px;
}

.compareCardsRow2 {
    display: flex;
    gap: 25px;
    height: 600px;
}

.compareCard2 {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 56%;

}

.compareCard2>img {
    object-fit: contain;
    width: 430px;
    margin-left: auto;
}

.compareCard2>p {
    width: 275px;
}

#card2text1 {
    font-weight: 600;
    color: var(--gray-text);
}

#card2text2 {
    color: #64F4D1;
    font-weight: 700;
}

.compareCard3 {
    display: flex;
    flex-direction: column;

    width: 44%;
}

.card3Text {
    padding-bottom: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card3Text>h5 {
    color: #C1FF3C;
}

.card3Text>p {
    width: 240px;
    line-height: 26px !important;
}

.card-home {
    background-color: rgba(34, 36, 43, .47);
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, .07);
}

.cardText-home>h5 {
    font-weight: 600;
    font-size: 17px;
    line-height: 39px;
}

.cardText-home>h4 {
    font-size: 35px;
    line-height: 41px;
    font-weight: 700;
}

.cardText-home>p {
    font-size: 18px;
    color: var(--gray-text);
    font-weight: 500;
    line-height: 32px;
}

/* search engine section */
.searchEngine {

    margin-top: 190px;
}


.searchCards>h2 {
    width: 505px;
}

#span2 {
    color: #3BA4FF;
}

.searchCards>p {
    max-width: 610px;
}

.searchCardsRow {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.searchCard1 {
    width: 50%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;

}

.searchCard1>h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;

}

.searchCard1>h3 {
    font-weight: 900;
    font-size: 39px;
    line-height: 43px;
    background: linear-gradient(to bottom right, #3BA4FF, #317CBC);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 45px;
    width: 290px;
    text-align: center;
}

.searchCard1>img {
    width: 379px;

}

.searchCard2 {
    width: 50%;
    height: 600px;
    background-image: linear-gradient(to bottom right, #4A90E2, #2B63A4);
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90px 0 50px 0;
}

.searchCard2>img {
    margin-bottom: auto;
    width: 150px;
}

.searchCard2>h3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    width: 290px;
    margin-top: 70px;
}

.searchCard2>h3>span {
    color: #ABD8FF;
}

.searchCard2>p {
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    color: var(--gray-text);
    text-align: center;
    width: 310px;
}


/* one app section */
.oneApp {


    margin-top: 190px;
}


.appCards>h2 {
    width: 480px;
}

#span3 {
    background: linear-gradient(to right, #AB4DFD, #4E2A72);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.appCards>p {
    max-width: 775px;
}

.appCard1 {

    display: flex;
    justify-content: space-between;
    height: 435px;
}

.appCard1>img {
    height: 400px;
    object-fit: contain;
    margin-top: auto;
}

.card6Text {
    max-width: 350px;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
}

.card6Text>h5 {
    color: var(--purple);
}

.card6Text>p {
    margin-top: auto;
    padding-bottom: 10px;
}

.mobileCardText {
    display: none;
}

.appCardsRow2 {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.appCard2 {
    width: 50%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 90px;
    padding-bottom: 50px;
}

.appCard2>img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px auto;
    margin-bottom: auto;

}

.card7Text {
    width: 305px;
}

.card7Text>h5 {
    color: var(--purple);
}

.card7Text>p {
    line-height: 26px;
}

.appCard3 {
    width: 50%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 50px;
    gap: 15px;

}

.appCard3>h4 {
    width: 220px;
}

.appCard3>h4>span {
    color: #A64AF7;
}

.appCard3>p {
    font-size: 16px !important;
    line-height: 23px !important;
    width: 230px;
}

.appCard3>a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #A64AF7;
    border-radius: 60px;
    height: 50px;
}

.appCard3>a>p {
    font-weight: 700;
    font-size: 15px;
    line-height: 39px;
    color: black;
}

.lg-text-container>p {
    color: white;
    font-weight: 500;
    font-family: sans-serif;
    opacity: 0.8;
}

.lg-text-container>h2 {
    color: white;
    font-weight: 500;
    font-family: sans-serif;
    opacity: 0.8;

}

.cities-grid>li {
    color: white;
    opacity: 0.6;
    font-weight: 500;
    font-family: sans-serif;
}

.lg-text-container>div>h2 {
    color: white;
    font-weight: 500;
    font-family: sans-serif;

}

.lg-text-container {
    max-width: 767px;
    margin: auto;
    padding: 0px 25px;
    margin-top: 140px;
}

.lg-text-container>h1 {
    color: white;
}

.lg-text-container>p>a {
    color: var(--main-color);
    font-weight: 600;
    text-decoration: underline;
}



.cities-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    list-style-type: none;
    gap: 10px;
}

.cities-title {
    margin-bottom: 15px;
    font-size: 17px !important;
    font-weight: bold;
    margin-top: 56px;
}



footer {
    width: 100%;
    position: relative;
    background-color: rgba(34, 36, 43, .47);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.11);


}

footer>h1 {
    width: 420px;
    text-align: center;
    font-size: 57px;
    line-height: 59px;
    font-weight: 700;
    margin-top: 85px;
}

footer>h1>span {
    color: #64F4D1;

}

footer>p {
    width: 345px;
    text-align: center;
    font-size: 18px;
    line-height: 29px;
    color: var(--gray-text);
    font-weight: 500;
    margin-top: 10px;
}

footer>a {
    margin-top: 15px;
}


.marquee {

    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    height: 770px;
}

.marqueeContent {
    display: flex;
    animation: marquee 20s linear infinite;
    position: relative;
    top: 80px;
}

.marqueeContent>img {
    width: 400px;
    height: auto;
    margin-right: 40px;
}

@keyframes marquee {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(0);
    }
}




@media screen and (max-width: 800px) {
    .cities-grid {
        grid-template-columns: 1fr 1fr;
    }

    .lg-text-container {
        max-width: 767px;
        margin: auto;
        padding: 0px 25px;
        margin-top: 85px;

    }

    .lg-text-container>h1 {
        margin-bottom: -25px;
        margin-top: 10px;
    }

    nav {
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 10px;
        gap: 10px;
    }


    .scrolled-nav {
        background-color: #22242bcf;

        backdrop-filter: blur(10px);
        width: 90%;
        padding-left: 20px;
        gap: 10px;
    }

    nav>ul {
        display: none;
    }

    nav>a>p {
        font-weight: 700;
        font-size: 15px;


    }

    #logo {
        border-radius: 9px;
        height: 39px;
    }

    #download {
        display: none;
    }

    #downloadMobile {
        display: flex;
        width: 95px;
        height: 30px;
        border-radius: 40px;
        border: 1px solid rgba(255, 255, 255, .32);
        margin-left: auto;
    }

    #downloadMobile>p {
        font-size: 11px;
        line-height: 26px;
        font-weight: 600;
    }

    nav>button {
        display: flex;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        margin-right: 10px;


    }

    nav>button>img {
        width: 34px;
        height: 34px;
    }

    .mobileMenu {
        position: fixed;
        width: 100%;
        height: 100vh;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        background-color: rgb(30, 30, 30);
        padding-bottom: 60px;
        z-index: 3;
        top: 0px;
    }

    .mobileMenu>ul {
        width: 100%;
        border-width: 1px 0px;
        border-color: #22242B;
        border-style: solid;
        top: 80px;
        position: absolute;

    }

    hr {
        width: 100%;
        border-width: 1px 0px;
        border-color: #22242B;
        border-style: solid;
        /* why border wont wont? */
    }

    .mobileMenu>ul>li:hover,
    .mobileMenu>ul>li:active {
        background-color: #22242B;
    }

    .mobileMenu>ul>li>a {
        text-decoration: none;
        font-size: 25px;
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mainContent {
        height: auto;
    }

    #circ3,
    #circ4,
    #circ5,
    #circ6,
    #circ7,
    #circ8 {
        display: none;
    }

    .hero {
        height: 870px;
    }



    .heroContent {
        bottom: -127px;

    }

    .heroContent>h1 {
        width: 315px;
        font-size: 38px;
        line-height: 43px;
        margin-top: 20px;
    }

    .heroContent>p {
        width: 280px;
        font-size: 16px;
        line-height: 26px;
        margin-top: 0px;
    }

    .heroContent>img {

        margin-right: 230px;
        margin-top: 50px;
        width: 565px;
    }

    /* comparefood */

    .cardsContainer-home {
        align-items: center;
    }

    .compareCards>h2 {
        width: 270px
    }

    .h2Home {
        font-weight: 900;
        font-size: 38px;
        line-height: 43px;
        text-align: center;
    }

    .p-home {
        display: none;
    }

    .card-home {
        width: 360px;
        border: none;
        background-color: #22242B;
    }



    .compareCard1 {
        flex-direction: column-reverse;
        height: auto;
        padding: 0;
        align-items: center;
        margin-top: 25px;
    }

    .compareCard1>img {
        width: 300px;
        height: auto;
    }

    .card1Text {
        align-items: center;
        padding: 20px 0 30px 0;
    }

    .card1Text>h4 {
        text-align: center;
        width: 280px;
    }

    .cardText-home>h5 {
        font-size: 14px;
        line-height: 25px;
    }

    .cardText-home>h4 {

        font-size: 32px;
        font-weight: 900;
        line-height: 39px;
    }

    .cardText-home>p {
        line-height: 23px;
        font-size: 16px;

    }

    .card1Text>p {
        display: none;
    }

    #card2text1 {
        display: none;
    }

    .compareCardsRow2 {
        flex-direction: column;
        height: auto;
    }

    .compareCard2 {
        padding: 0;


    }

    .compareCard2>p {
        width: 255px;
        margin: 25px 0 20px 30px;
    }

    .compareCard2>img {
        margin: 0;
        width: 410px;
        position: relative;
        right: 73px;
    }

    .compareCard3 {
        position: relative;
    }

    .compareCard3>img {
        width: 450px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .card3Text {
        padding: 0 30px;
        margin: 25px 0;
    }

    /* searchengine */
    .searchEngine {
        margin-top: 135px;
    }

    .searchCards>h2 {
        width: 270px
    }

    .searchCardsRow {
        flex-direction: column;
        height: auto;
        margin-top: 25px;
    }

    .searchCard1 {
        height: auto;
        padding: 25px 0 0 0;
    }

    .searchCard1>h5 {

        font-size: 14px;

    }

    .searchCard1>h3 {
        font-size: 34px;
        line-height: 39px;
        margin-bottom: 30px;
        width: 245px
    }

    .searchCard2 {
        height: auto;
        padding: 60px 30px 50px 30px;
    }

    .searchCard2>img {
        width: 115px;
    }

    .searchCard2>h3 {
        margin-top: 60px;
        line-height: 39px;
        font-size: 32px;
        width: 280px;

    }

    .searchCard2>p {
        display: none;
        width: 220px;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
    }


    /* oneapp */
    .oneApp {
        margin-top: 135px;
    }

    .appCards>h2 {
        width: 253px
    }

    .appCard1 {
        flex-direction: column-reverse;
        height: auto;
        padding: 0;
        margin-top: 25px;

    }

    .appCard1>img {
        background-color: var(--purple);
        padding: 25px 30px 0px 30px;
        height: auto;
        width: 100%;
    }

    .card6Text {
        padding: 25px 0px 30px 30px;
    }

    .card6Text>h5 {
        display: none;
    }

    .card6Text>p {
        display: none;
        padding: 0;
        width: 230px;
    }

    .card6Text>h4 {
        width: 265px;
    }

    .card6Text>h4>span {
        color: var(--purple);
    }

    .mobileCardText {
        display: inline !important;
    }


    .appCardsRow2 {
        flex-direction: column;
        height: auto;
    }

    .appCard2 {
        height: auto;
        padding: 85px 30px 30px 30px;
    }

    .appCard2>img {
        width: 115px;
        margin-top: 0;
    }

    .card7Text {
        margin-top: 85px;
    }

    .card7Text>h4 {
        width: 265px;
    }

    .card7Text>p {
        width: 230px;
    }

    .card7Text>h5 {
        display: none;
    }

    .card7Text>h4>span {
        color: var(--purple);
    }

    .appCard3 {
        padding: 25px 30px 30px 30px;
        gap: 5px;
        height: fit-content;
    }

    .appCard3>a {
        margin-top: 25px;

    }

    .download-btn>img {
        width: 175px;
        margin-top: 14px;
    }

    footer>h1 {
        width: 270px;
        font-size: 38px;
        line-height: 43px;
        margin-top: 20px;
    }

    footer>p {
        width: 310px;
        font-size: 16px;
        line-height: 26px;
        margin-top: 0px;
    }

}